<template>
  <div class="view-contanier">
    <div class="header">
      <div class="tab">
        <span
          v-permissson="'YMXC'"
          class="tabItem"
          :class="{ active: active === 2 }"
          @click="changeType(2)"
        >亚马逊仓({{ tabNumer.find(a => a.type === 2)?.qty || 0 }})</span
        >
        <span
          v-permissson="'SC'"
          class="tabItem"
          :class="{ active: active === 3 }"
          @click="changeType(3)"
        >私仓({{ tabNumer.find(a => a.type === 3)?.qty || 0 }})</span
        >
        <span
          v-permissson="'ZT'"
          class="tabItem"
          :class="{ active: active === 4 }"
          @click="changeType(4)"
        >自提({{ tabNumer.find(a => a.type === 4)?.qty || 0 }})</span
        >
      </div>
      <div class="opt">
        <div class="search">
          <el-popover
            ref="popover"
            placement="top"
            class="searchPop"
            title="批量搜索"
            trigger="click"
            width="378"
          >
            <div class="close" @click="closeSearchPanel">
              <i class="el-icon-close"></i>
            </div>
            <base-muti-input
              ref="baseMutiInput"
              v-model="referenceNumber"
            ></base-muti-input>
            <div style="text-align: right; margin: 16px 0 0">
              <el-button size="small" @click="handleEmpty">清空</el-button>
              <el-button
                size="small"
                type="primary"
                @click="handleConfirm"
              >确定</el-button
              >
            </div>
          </el-popover>
          <el-input
            :value="searchVal"
            placeholder="请输入提单号/柜号/客户单号"
            class="input-search"
            @focus="handleFocus"
          ></el-input>
          <div class="btn-search">
            <i class="el-icon-search"></i>
          </div>
        </div>
        <div class="download" @click="download">
          <img src="@/assets/images/bill/download-2.png" />
          导出
        </div>
        <div
          v-for="(item, $index) in addList"
          v-show="active === $index + 2"
          :key="item"
          v-permissson="`${item}`"
          class="upload"
          @click="dialogVisible = true"
        >
          <img src="@/assets/images/bill/upload-2.png" />
          上传BOL
        </div>
        <base-filter
          ref="BaseFilter"
          :isActive="isActive"
          :width="892"
          @sendFilter="sendFilter"
          @reset="reset"
        >
          <div class="filter">
            <el-form ref="form" :model="filterList" label-width="88px">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="ETA">
                    <el-date-picker
                      v-model="filterList.eta"
                      style="width: 310px"
                      type="daterange"
                      range-separator="至"
                      align="center"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      unlink-panels
                      :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="客户">
                    <el-select
                      v-model="filterList.companyId"
                      filterable
                      style="width: 310px"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in companyDrapdown"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="到仓日期">
                    <el-date-picker
                      v-model="filterList.arrival"
                      style="width: 310px"
                      type="daterange"
                      range-separator="至"
                      align="center"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      unlink-panels
                      :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="销售员">
                    <el-select
                      v-model="filterList.saleIds"
                      style="width: 310px"
                      filterable
                      placeholder="请选择"
                      multiple
                    >
                      <el-option
                        v-for="item in userOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="出仓日期">
                    <el-date-picker
                      v-model="filterList.departure"
                      style="width: 310px"
                      type="daterange"
                      range-separator="至"
                      align="center"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      unlink-panels
                      :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="仓库/邮编">
                    <el-input
                      v-model="filterList.code"
                      style="width: 310px"
                      placeholder="请输入仓库/邮编"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-row>
                    <el-form-item label="BOL">
                      <el-radio-group v-model="filterList.isBOL" style="width: 310px">
                        <el-radio label="ALL">全部</el-radio>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="POD">
                      <el-radio-group v-model="filterList.isPOD" style="width: 310px">
                        <el-radio label="ALL">全部</el-radio>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="ISA">
                      <el-radio-group v-model="filterList.isISA" style="width: 310px">
                        <el-radio label="ALL">全部</el-radio>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="出仓">
                      <el-radio-group v-model="filterList.isDeparture" style="width: 310px">
                        <el-radio label="ALL">全部</el-radio>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-row>
                </el-col>
                <!-- <el-col :span="12">
                  <el-form-item>
                    <div slot="label" style="line-height: 20px">
                      提单号/柜号<br />/客户单号
                    </div>
                    <base-muti-input
                      ref="baseMutiInput2"
                      v-model="filterList.numberArray"
                      style="width: 310px"
                    ></base-muti-input>
                  </el-form-item>
                </el-col> -->
              </el-row>
            </el-form>
          </div>
        </base-filter>
      </div>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :pageIndex="pagerConfig.pageIndex"
        :pageSize="pagerConfig.pageSize"
        :tableData="tableData"
        :loading="loading"
        @handlePageChange="handlePageChange"
        @handleSelectionChange="handleSelectionChange"
      >
        <template slot="arrivalDate" slot-scope="row">
          <p style="text-align: left;">
            <span class="lable">到仓件数</span>
            <span class="value">{{ row.scope.arrivalQty }}</span>
          </p>
          <p style="text-align: left;">
            <span class="lable">到仓日期</span>
            <span class="value">{{
              row.scope.arrivalDate | formatterTime
            }}</span>
          </p>
        </template>
        <template slot="departureDate" slot-scope="row">
          <p style="text-align: left;">
            <span class="lable">出仓件数</span>
            <span class="value">{{ row.scope.departureQty }}</span>
          </p>
          <p style="text-align: left;">
            <span class="lable">出仓板数</span>
            <span class="value">{{ row.scope.departureCbm }}</span>
          </p>
          <p style="text-align: left;">
            <span class="lable">出仓日期</span>
            <span class="value">{{
              row.scope.departureDate | formatterTime
            }}</span>
          </p>
        </template>
        <template slot="isException" slot-scope="row">
          <span
            :class="{
              success: !row.scope.isException,
              error: row.scope.isException,
            }"
          >
            {{ row.scope.isException ? '异常' : "正常" }}
            <el-tooltip
              v-if="row.scope.isException"
              class="item"
              effect="dark"
              :content="row.scope.exceptionReason"
              placement="bottom"
            >
              <svg-icon className="icon" iconClass="warning" />
            </el-tooltip>
          </span>
        </template>
        <template slot="deliveryStatus" slot-scope="row">
          <span
            :class="{
              success: row.scope.deliveryStatus,
              error: !row.scope.deliveryStatus,
            }"
          >
            {{ !row.scope.deliveryStatus ? "未完成" : "已完成" }}
          </span>
        </template>
        <template slot="podDate" slot-scope="row">
          <p style="text-align: left;">
            <span class="lable">POD</span>
            <span class="value">{{ row.scope.isPOD ? "是" : "否" }}</span>
          </p>
          <p style="text-align: left;">
            <span class="lable">POD日期</span>
            <span class="value">{{ row.scope.podDate | formatterTime }}</span>
          </p>
          <p style="text-align: left;">
            <span class="lable">POD时效</span>
            <span class="value">{{ row.scope.podPrescription }}</span>
          </p>
        </template>
        <template slot="deliveryQty" slot-scope="row">
          <p style="text-align: left;">
            <span class="lable">递送件数</span>
            <span class="value">{{ row.scope.deliveryQty }}</span>
          </p>
          <p style="text-align: left;">
            <span class="lable">递送板数</span>
            <span class="value">{{ row.scope.deliveryCbm }}</span>
          </p>
          <p style="text-align: left;">
            <span class="lable">递送日期</span>
            <span class="value">{{ row.scope.deliveryDate | formatterTime }}</span>
          </p>
          <p style="text-align: left;">
            <span class="lable">递送时效</span>
            <span class="value">{{ row.scope.deliveryPrescription }}</span>
          </p>
        </template>
        <template slot="recievebleAmount" slot-scope="row">
          <p style="text-align: left;">
            <span class="lable">应收</span>
            <span class="value">{{ row.scope.recievebleAmount }} {{ row.scope.currency }}</span>
          </p>
          <p style="text-align: left;">
            <span class="lable">应付</span>
            <span class="value">{{ row.scope.payableAmount }} {{ row.scope.currency }}</span>
          </p>
        </template>
        <template slot="opt" slot-scope="row">
          <el-button
            v-permissson="'detail'"
            type="text"
            @click="detail(row.scope)"
          >详情</el-button
          >
        </template>
        <template slot="custom-total">
          <div class="custom-total">
            <span>已选 {{ selectionItem.length }} 项</span>
            <div class="btn-success" @click="handleSuccess">标为正常</div>
            <div class="btn-error" @click="handleError">标为异常</div>
          </div>
        </template>
      </BaseTable>
      <!-- <div class="footer">
        <span>已选 2 项</span>
        <div class="btn-success" @click="handleSuccess">标为正常</div>
        <div class="btn-error" @click="handleError">标为异常</div>
      </div> -->
    </div>
    <ErrorDialog
      :dialogVisible="dialogVisible1"
      @cancel="dialogVisible1 = false"
      @ok="ok1"
    ></ErrorDialog>
    <UploadBolDialog :dialogVisible="dialogVisible" :mblSendWayIds="rowData?.id" :currency="rowData?.currency" @cancel="dialogVisible = false" @ok="ok"></UploadBolDialog>
    <TruckDetailDrawer :drawer="drawer" :mblSendWayId="rowData?.id" @close="drawer = false"></TruckDetailDrawer>
  </div>
</template>
<script>
let that
const BaseFilter = () => import('@/components/filter/BaseFilter')
const BaseTable = () => import('@/components/table/BaseTable')
const BaseMutiInput = () => import('@/components/base/BaseMutiInput.vue')
import UploadBolDialog from './components/UploadBolDialog.vue'
import TruckDetailDrawer from './components/TruckDetailDrawer.vue'
import ErrorDialog from './components/ErrorDialog.vue'
import { getTruckPages, markNormal, markException } from '@/api/bill'
import { mapGetters } from 'vuex'
import { getSaleList } from '@/api/system'
import { getRecentMonthDate, permissions, derive } from '@/utils/common'
import { myMixins } from '@/mixins'
export default {
  name: 'TruckList',
  components: {
    BaseFilter,
    BaseTable,
    BaseMutiInput,
    UploadBolDialog,
    ErrorDialog,
    TruckDetailDrawer
  },
  filters: {
    formatterTime(date) {
      if (date) {
        return date.substr(0, 16)
      } else {
        return ''
      }
    },
    formatterTab(item, val) {
      console.log('val', val)
      return that?.tabNumer.find(a => a.type === val)?.qty || 0
    }
  },
  mixins: [myMixins],
  data() {
    return {
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
      active: 2,
      height: 0,
      filterList: {
        eta: '',
        arrival: '',
        departure: '',
        code: '',
        saleIds: '',
        numberArray: [],
        isPOD: 'ALL',
        isISA: 'ALL',
        isBOL: 'ALL',
        isDeparture: 'ALL',
        companyId: ''
      },
      isActive: false,
      tableData: {
        fileds: [
          {
            filed: 'code',
            label: '仓库',
            width: '80px',
            fixed: 'left'
          },
          {
            filed: 'companyName',
            label: '客户',
            width: '230',
            fixed: 'left'
          },
          {
            filed: 'blNo',
            label: '提单号',
            width: '230',
            fixed: 'left',
            isCopy: true,
            align: 'left'
          },
          {
            filed: 'containerNumber',
            label: '柜号',
            width: '120px',
            isCopy: true
          },
          {
            filed: 'qty',
            label: '件数',
            width: '100px'
          },
          {
            filed: 'arrivalDate',
            label: '到仓信息',
            width: '240px',
            isCustom: true
          },
          {
            filed: 'departureDate',
            label: '出仓时间',
            width: '240px',
            isCustom: true
          },
          {
            filed: 'isException',
            label: '是否异常',
            width: '100px',
            isCustom: true
          },
          {
            filed: 'deliveryStatus',
            label: '递送状态',
            width: '100px',
            isCustom: true
          },
          {
            filed: 'podDate',
            label: 'POD信息',
            width: '240px',
            isCustom: true
          },
          {
            filed: 'deliveryQty',
            label: '递送信息',
            width: '240px',
            isCustom: true
          },
          {
            filed: 'recievebleAmount',
            label: '费用信息',
            width: '240px',
            isCustom: true
          },
          {
            filed: 'isISA',
            label: 'ISA',
            width: '80px',
            formatter: (row) => {
              return row.isISA ? '是' : '否'
            }
          },
          {
            filed: 'isBOL',
            label: 'BOL',
            width: '80px',
            formatter: (row) => row.isBOL ? '是' : '否'
          },
          {
            filed: 'customerNo',
            label: '客户单号',
            width: '100px'
          },
          {
            filed: 'weight',
            label: '重量',
            width: '80px',
            formatter: (row) => {
              return `${row.weight ? row.weight : 0} KG`
            }
          },
          {
            filed: 'square',
            label: '方数',
            width: '80px',
            formatter: (row) => {
              return `${row.square ? row.square : 0} CBM`
            }
          },
          {
            filed: 'destinationHarborName',
            label: '收货地址',
            width: '320px'
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '180'
          },
          {
            filed: 'saleName',
            label: '销售员',
            width: '180'
          },
          {
            filed: 'opt',
            label: '操作',
            width: '120px',
            isCustom: true,
            fixed: 'right'
          }
        ],
        mutiCheck: true,
        columns: [],
        total: 0
      },
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      userOptions: [],
      selectionItem: [],
      loading: false,
      dialogVisible: false,
      dialogVisible1: false,
      drawer: false,
      addList: ['add_a', 'add_b', 'add_c'],
      rowData: null,
      tabNumer: [],
      searchVal: '',
      referenceNumber: ''
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown'])
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 43
    if (!this.$store.state.connect.connect.tabCode) {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const codeList = ['YMXC', 'SC', 'ZT']
      const childrenCode = menu.children.map(a => a.code)
      const codeIndex = codeList.findIndex((a) => childrenCode.includes(a))
      this.active = codeIndex === -1 ? codeIndex : codeIndex + 2
    }
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
    that = this
  },
  created() {
    this.filterList.eta = getRecentMonthDate(1)
    this.getList(this.pagerConfig)
    this.getUserList()
  },
  methods: {
    async getList(param) {
      this.loading = true
      param = {
        ...param,
        queryParam: {
          type: this.active,
          ...this.formatterFilter(),
          numberArray: this.searchVal ? this.searchVal.split(';') : undefined
        }
      }
      try {
        const res = await getTruckPages(param)
        this.loading = false
        if (res.success) {
          this.tableData.columns = res.data.list
          this.tableData.total = res.data.total
          this.tabNumer = res.data.tabNumer
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    getUserList() {
      getSaleList().then((res) => {
        if (res.success) {
          const arr = []
          res.data.map((item) => {
            arr.push({
              value: item.id,
              label: item.nameCH
            })
          })
          this.userOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    formatterFilter() {
      return {
        etaStartDate: this.filterList.eta ? this.filterList.eta[0] : undefined,
        etaEndDate: this.filterList.eta ? this.filterList.eta[1] : undefined,
        arrivalStartDate: this.filterList.arrival
          ? this.filterList.arrival[0]
          : undefined,
        arrivalEndDate: this.filterList.arrival
          ? this.filterList.arrival[1]
          : undefined,
        departureStartDate: this.filterList.departure
          ? this.filterList.departure[0]
          : undefined,
        departureEndDate: this.filterList.departure
          ? this.filterList.departure[1]
          : undefined,
        code: this.filterList.code ? this.filterList.code : undefined,
        // numberArray:
        //   this.filterList.numberArray.length > 0
        //     ? this.filterList.numberArray
        //     : undefined,
        isPOD:
          this.filterList.isPOD && this.filterList.isPOD !== 'ALL'
            ? this.filterList.isPOD
            : undefined,
        isISA:
          this.filterList.isISA && this.filterList.isISA !== 'ALL'
            ? this.filterList.isISA
            : undefined,
        isBOL:
          this.filterList.isBOL && this.filterList.isBOL !== 'ALL'
            ? this.filterList.isBOL
            : undefined,
        isDeparture:
          this.filterList.isDeparture && this.filterList.isDeparture !== 'ALL'
            ? this.filterList.isDeparture
            : undefined,
        companyId: this.filterList.companyId
          ? this.filterList.companyId
          : undefined,
          saleIds: this.filterList.saleIds ? this.filterList.saleIds : undefined
      }
    },
    changeType(type) {
      this.active = type
      // this.initList()
      const param = {
        pageIndex: 1,
        pageSize: this.pagerConfig.pageSize
      }
      this.pagerConfig = param
      this.selectionItem = []
      this.getList(param)
    },
    initList() {
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getList({
        pageIndex: 1,
        pageSize: 20
      })
    },
    handlePageChange(pager) {
      this.pagerConfig = pager
      this.getList(pager)
    },
    sendFilter() {
      if (
        this.filterList.eta.length > 0 ||
        this.filterList.arrival.length > 0 ||
        this.filterList.departure.length > 0 ||
        // this.filterList.numberArray.length > 0 ||
        this.filterList.code ||
        this.filterList.isPOD !== 'ALL' ||
        this.filterList.isISA !== 'ALL' ||
        this.filterList.isBOL !== 'ALL' ||
        this.filterList.isDeparture !== 'ALL' ||
        this.filterList.companyId ||
        this.filterList.saleIds
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      this.initList()
    },
    reset() {
      this.$refs.baseMutiInput.reset()
      this.filterList = {
        eta: getRecentMonthDate(1),
        arrival: '',
        departure: '',
        code: '',
        userId: '',
        // numberArray: [],
        isPOD: 'ALL',
        isISA: 'ALL',
        isBOL: 'ALL',
        isDeparture: 'ALL',
        companyId: '',
        saleIds: ''
      }
      this.isActive = false
    },
    handleSelectionChange(items) {
      this.selectionItem = items
    },
    async handleSuccess() {
      if (this.selectionItem.length === 0) {
        return false
      }
      const arr = this.selectionItem.map((item) => {
        return item.id
      })
      try {
        const res = await markNormal(arr)
        if (res.success) {
          this.$message.success('标记成功')
          this.initList()
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleError() {
      if (this.selectionItem.length === 0) {
        return false
      }
      this.dialogVisible1 = true
    },
    ok() {
      this.dialogVisible = false
      this.dialogVisible1 = false
      this.initList()
      this.dialogVisible = false
    },
    async ok1(reason) {
      this.dialogVisible1 = false
      const arr = this.selectionItem.map((item) => {
        return item.id
      })
      try {
        const res = await markException({
          ids: arr,
          exceptionReason: reason
        })
        if (res.success) {
          this.$message.success('标记成功')
          this.initList()
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    detail(row) {
      this.drawer = true
      this.rowData = row
    },
    async download() {
      const params = {
        type: this.active,
        ...this.formatterFilter()
      }
      derive('MBL/exportTruckExcel', 'post', params)
    },
    handleFocus() {
      this.$refs.popover.doShow()
    },
    closeSearchPanel() {
      this.$refs.popover.doClose()
    },
    handleEmpty() {
      this.referenceNumber = ''
      this.searchVal = ''
      this.$refs.baseMutiInput.reset()
      this.$refs.popover.doClose()
      this.initList()
    },
    handleConfirm() {
      if (this.referenceNumber && this.referenceNumber !== '') {
        this.searchVal = this.referenceNumber.join(';')
        this.$refs.popover.doClose()
      }
      // todo 重置filter
      this.filterList = {}
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = param
      this.isActive = false
      this.getList(param)
    }
  }
}
</script>
<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .header {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tabItem {
    padding: 20px;
    width: 42px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    cursor: pointer;
  }
  .active {
    color: #2395f3;
  }
  .opt {
    display: flex;
    .search {
      position: relative;
      display: flex;
      width: 240px;
      margin-right: 5px;
      .searchPop {
        /deep/ .el-popover {
          z-index: 9999;
          right: 0;
        }
        .close {
          position: absolute;
          top: 6px;
          right: 10px;
          cursor: pointer;
          font-size: 20px;
        }
      }
      .input-search {
        width: 206px;
        height: 30px !important;
        /deep/ .el-input__inner {
          height: 30px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .btn-search {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33px;
        height: 30px;
        border: 1px solid #dbe4f2;
        border-left: 0;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
        box-sizing: border-box;
      }
    }
    .download {
      width: 80px;
      height: 28px;
      border-radius: 4px;
      opacity: 1;
      border: 1px solid #2395f3;
      font-size: 14px;
      font-weight: 400;
      color: #2395f3;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      img {
        vertical-align: sub;
      }
    }
    .upload {
      width: 100px;
      height: 30px;
      background: #2395f3;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      margin: 0 5px;
      img {
        vertical-align: sub;
      }
    }
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    position: relative;
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
  }
  .custom-total {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    color: #999999;
    height: 42px;
    font-weight: 400;
    font-size: 14px;
    z-index: 1000;
    .btn-success {
      width: 80px;
      height: 30px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
      border: 1px solid #35ba83;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #35ba83;
      line-height: 30px;
      text-align: center;
      margin-left: 20px;
      cursor: pointer;
    }
    .btn-error {
      width: 80px;
      height: 30px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
      border: 1px solid #fe453d;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #fe453d;
      line-height: 30px;
      text-align: center;
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .lable {
    width: 80px;
    display: inline-block;
    height: 20px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    background: #f7f7f7;
    border-radius: 2px 2px 2px 2px;
    vertical-align: bottom;
    text-align: center;
  }
  .value {
    height: 20px;
    font-size: 14px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    line-height: 20px;
    margin-left: 5px;
  }
  .success {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #35ba83;
    background: rgba(53, 186, 131, 0.1);
    line-height: 16px;
    padding: 5px 18px;
  }
  .error {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: rgba(254, 69, 61, 1);
    background: rgba(254, 69, 61, .1);
    line-height: 16px;
    padding: 5px 18px;
  }
  .icon {
    font-size: 16px;
    vertical-align: sub;
    margin-left: -4px;
    cursor: pointer;
  }
}
</style>

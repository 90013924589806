<template>
  <el-dialog
    v-drag
    title="上传POD"
    :visible.sync="dialogVisible"
    width="800px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div v-loading="loading" class="content">
      <el-form
        ref="form"
        class="form"
        :model="form"
        :rules="rules"
        label-position="top"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item prop="PODDate" label="POD时间">
              <el-date-picker
                v-model="form.PODDate"
                style="width: 320px;"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="deliveryQty" label="件数">
              <el-input-number
                v-model="form.deliveryQty"
                style="width: 320px;"
                controls-position="right"
                :min="1"
                :max="10000"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="deliveryDate" label="递送时间">
              <el-date-picker
                v-model="form.deliveryDate"
                style="width: 320px;"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="deliveryCbm" label="板数">
              <el-input-number
                v-model="form.deliveryCbm"
                style="width: 320px;"
                controls-position="right"
                :min="1"
                :max="10000"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="BOLId" label="选择BOL">
              <el-select
                v-model="form.BOLId"
                style="width: 320px"
                placeholder="请选择BOL"
              >
                <el-option
                  v-for="(item, $index) in options"
                  :key="$index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <p class="label"><span class="require">*</span> 选择POD文件</p>
            <div class="upload">
              <el-upload
                class="upload-demo"
                action="#"
                :limit="1"
                :file-list="fileList"
                :show-file-list="false"
                :http-request="cutomerRequest"
              >
                <el-button class="btn" size="small">
                  <svg-icon iconClass="upload" className="icon" />
                  选择文件上传
                </el-button>
              </el-upload>
            </div>
            <div v-if="file" class="fileList">
              <div class="file">
                <div class="qun">
                  <el-progress
                    type="circle"
                    :width="40"
                    :height="40"
                    :stroke-width="4"
                    :percentage="100"
                  >
                  </el-progress>
                </div>
                <div class="file-item">
                  <p class="fileName">{{ file.name }}</p>
                  <p class="fileSize">{{ file.size | formatSize }} mb</p>
                </div>
              </div>
              <div class="opt">
                <i class="el-icon-delete" @click="deleteFile" />
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div v-loading="loading" class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import { uploadPOD } from '@/api/bill'
export default {
  filters: {
    formatSize(size) {
      return (size / 1024 / 1024).toFixed(2)
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    bolOptions: {
      type: Array,
      default: function() {
        return []
      }
    },
    mblSendTypeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        PODDate: '',
        deliveryDate: '',
        deliveryQty: '',
        deliveryCbm: '',
        BOLId: ''
      },
      rules: {
        PODDate: [{ required: true, message: '请输入POD时间', trigger: 'blur' }],
        deliveryDate: [{ required: true, message: '请输入递送时间', trigger: 'blur' }],
        deliveryQty: [{ required: true, message: '请输入件数', trigger: 'blur' }],
        deliveryCbm: [{ required: true, message: '请输入板数', trigger: 'blur' }]
      },
      fileList: [],
      file: null,
      loading: false
    }
  },
  computed: {
    options() {
      return this.bolOptions.map((item) => {
        return {
          label: item.supplierCode,
          value: item.id
        }
      })
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.$refs['form']?.resetFields()
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          if (this.bolOptions.length !== 0 && !this.form.BOLId) {
            this.$message.warning('请上传BOL')
            return false
          }
          if (!this.file) {
            this.$message.warning('请上传文件')
            return false
          }
          this.loading = true
          try {
            const formData = new FormData()
            formData.set('file', this.file)
            formData.set('input.mblSendTypeId', this.mblSendTypeId)
            formData.set('input.PODDate', this.form.PODDate)
            formData.set('input.deliveryDate', this.form.deliveryDate)
            formData.set('input.deliveryQty', this.form.deliveryQty)
            formData.set('input.deliveryCbm', this.form.deliveryCbm)
            formData.set('input.BOLId', this.form.BOLId)
            const res = await uploadPOD(formData)
            console.log('res', res)
            this.loading = false
            if (res.data.success) {
              this.$message.success('上传成功')
              this.$emit('ok')
            } else {
              this.$message.error(res.data.errorMessage)
            }
          } catch (error) {
            this.loading = false
            console.log(error)
          }
        }
      })
    },
    cutomerRequest({ file }) {
      this.file = file
      console.log('res', file)
    },
    deleteFile() {
      this.fileList = []
      this.file = null
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .form {
    width: 100%;
    height: auto;
    &:deep(.el-form-item__label) {
      line-height: 30px;
    }
    .el-col:nth-child(2n) {
      display: flex;
      justify-content: flex-end;
    }
  }
  .label {
    width: auto;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
  }
  .require {
    color: #ff7444;
  }
  .upload {
    width: 320px;
    height: 100px;
    background: #f7f7f7;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    width: 160px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #2395f3;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    .icon {
      font-size: 24px;
      vertical-align: middle;
    }
  }
  .fileList {
    width: 300px;
    height: 40px;
    padding: 12px 10px;
    margin-top: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    display: flex;
    .file {
      display: flex;
      flex: 1;
    }
    .opt {
      line-height: 40px;
      font-size: 24px;
      color: #cdcdcd;
      cursor: pointer;
    }
  }
  .file-item {
    text-indent: 10px;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    line-height: 20px;
  }
  .fileName {
    color: #666666;
  }
  .fileSize {
    font-size: 12px;
    color: #999999;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 20px;
}

.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>

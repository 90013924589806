<template>
  <el-dialog
    v-drag
    title="新增卡派费用"
    :visible.sync="dialogVisible"
    width="800px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form
        ref="form"
        class="form"
        :model="form"
        :rules="rules"
        label-position="top"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item prop="supplierId" label="卡车公司">
              <el-select
                v-model="form.supplierId"
                style="width: 320px"
                placeholder="请选择类别"
              >
                <el-option
                  v-for="(item, $index) in supplyOptions"
                  :key="$index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="recievebleAmount" label="应收">
              <el-input-number
                v-model="form.recievebleAmount"
                style="width: 320px"
                oninput="value=value.replace(/[^0-9.]/g,'')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="payableAmount" label="应付">
              <el-input-number
                v-model="form.payableAmount"
                style="width: 320px"
                controls-position="right"
                oninput="value=value.replace(/[^0-9.]/g,'')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item prop="remark" label="备注">
              <el-input v-model="form.remark" style="width: 100%" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import { addTruckSurcharge } from '@/api/bill'
import { getSupplierDropDownList } from '@/api/system'
export default {
  filters: {
    formatSize(size) {
      return (size / 1024 / 1024).toFixed(2)
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    bolOptions: {
      type: Array,
      default: function() {
        return []
      }
    },
    mblSendTypeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        supplierId: '',
        recievebleAmount: 0.01,
        payableAmount: 0.01,
        remark: ''
      },
      rules: {
        supplierId: [{ required: true, message: '请选择卡车公司', trigger: 'change' }],
        recievebleAmount: [
          { required: true, message: '请输入应收', trigger: 'blur' }
        ],
        payableAmount: [
          { required: true, message: '请输入应付', trigger: 'blur' }
        ]
      },
      supplyOptions: [],
      fileList: [],
      file: null
    }
  },
  computed: {
    options() {
      return this.bolOptions.map((item) => {
        return {
          label: item.code,
          value: item.id
        }
      })
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getSupplyList()
        this.$refs['form']?.resetFields()
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    async getSupplyList() {
      try {
        const res = await getSupplierDropDownList({
          scopValue: 'J' // 业务范畴 (eg: 提T 拆C 交J 账号 Z 清 Q 多个用 ','隔开)
        })
        if (res.success) {
          this.supplyOptions = res.data.map((item) => {
            return {
              value: item.id,
              label: `${item.code}-${item.name}`
            }
          })
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    ok(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          try {
            const res = await addTruckSurcharge({
              ...this.form,
              mblSendTypeId: this.mblSendTypeId
            })
            console.log('res', res)
            if (res.success) {
              this.$message.success('新增成功')
              this.$emit('ok')
            } else {
              this.$message.error(res.errorMessage)
            }
          } catch (error) {
            console.log(error)
          }
          console.log()
        }
      })
    },
    cutomerRequest({ file }) {
      this.file = file
      console.log('res', file)
    },
    deleteFile() {
      this.fileList = []
      this.file = null
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .form {
    width: 100%;
    height: auto;
    &:deep(.el-form-item__label) {
      line-height: 30px;
    }
    .el-col:nth-child(2n) {
      display: flex;
      justify-content: flex-end;
    }
  }
  .label {
    width: auto;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
  }
  .require {
    color: #ff7444;
  }
  .upload {
    width: 320px;
    height: 100px;
    background: #f7f7f7;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    width: 160px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #2395f3;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    .icon {
      font-size: 24px;
      vertical-align: middle;
    }
  }
  .fileList {
    width: 300px;
    height: 40px;
    padding: 12px 10px;
    margin-top: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    display: flex;
    .file {
      display: flex;
      flex: 1;
    }
    .opt {
      line-height: 40px;
      font-size: 24px;
      color: #cdcdcd;
      cursor: pointer;
    }
  }
  .file-item {
    text-indent: 10px;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    line-height: 20px;
  }
  .fileName {
    color: #666666;
  }
  .fileSize {
    font-size: 12px;
    color: #999999;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 20px;
}

.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>

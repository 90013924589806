var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "上传BOL",
        visible: _vm.dialogVisible,
        width: "800px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "content",
          style: {
            "--calcWidth": _vm.step === 1 ? "calc(100% - 80px)" : "100%",
          },
        },
        [
          _c(
            "div",
            { staticClass: "step" },
            [
              _c(
                "div",
                { staticClass: "step-item", class: { active: _vm.step > 0 } },
                [
                  _c("svg-icon", {
                    attrs: { iconClass: "num-1", className: "icon" },
                  }),
                  _vm._v(" 填写BOL信息 "),
                ],
                1
              ),
              _c("svg-icon", {
                class: { active: _vm.step > 1 },
                attrs: { iconClass: "arrow", className: "icon" },
              }),
              _c(
                "div",
                { staticClass: "step-item", class: { active: _vm.step > 1 } },
                [
                  _c("svg-icon", {
                    attrs: { iconClass: "num-2", className: "icon" },
                  }),
                  _vm._v(" 选择派送方式 "),
                ],
                1
              ),
            ],
            1
          ),
          _vm.step === 1
            ? [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "form",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-position": "top",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 40 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "type", label: "上传类型" } },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.form.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "type", $$v)
                                      },
                                      expression: "form.type",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("亚马逊仓"),
                                    ]),
                                    _c("el-radio", { attrs: { label: 3 } }, [
                                      _vm._v("私仓"),
                                    ]),
                                    _c("el-radio", { attrs: { label: 4 } }, [
                                      _vm._v("自提"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "PRONo", label: "PRO#" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "320px" },
                                  model: {
                                    value: _vm.form.PRONo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "PRONo", $$v)
                                    },
                                    expression: "form.PRONo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 40 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "supplierId",
                                  label: "卡车公司",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "320px" },
                                    attrs: { placeholder: "请选择类别" },
                                    model: {
                                      value: _vm.form.supplierId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "supplierId", $$v)
                                      },
                                      expression: "form.supplierId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.supplyOptions,
                                    function (item, $index) {
                                      return _c("el-option", {
                                        key: $index,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "qty", label: "件数" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "320px" },
                                  attrs: {
                                    oninput:
                                      "value=value.replace(/[^0-9.]/g,'')",
                                  },
                                  model: {
                                    value: _vm.form.qty,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "qty", $$v)
                                    },
                                    expression: "form.qty",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 40 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "file", label: "选择文件" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "upload" },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        staticClass: "upload-demo",
                                        attrs: {
                                          action: "#",
                                          "show-file-list": false,
                                          "http-request": _vm.cutomerRequest,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "btn",
                                            attrs: { size: "small" },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                iconClass: "upload",
                                                className: "icon",
                                              },
                                            }),
                                            _vm._v(" 选择文件上传 "),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.form.file
                                  ? _c("div", { staticClass: "fileList" }, [
                                      _c("div", { staticClass: "file" }, [
                                        _c(
                                          "div",
                                          { staticClass: "qun" },
                                          [
                                            _c("el-progress", {
                                              attrs: {
                                                type: "circle",
                                                width: 40,
                                                height: 40,
                                                "stroke-width": 4,
                                                percentage: 100,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "file-item" },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "fileName" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.form.file.name)
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "fileSize" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatSize")(
                                                      _vm.form.file.size
                                                    )
                                                  ) + " mb"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "opt" }, [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          on: { click: _vm.deleteFile },
                                        }),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c("div", { staticClass: "filter" }, [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c(
                      "div",
                      { staticClass: "search" },
                      [
                        _c(
                          "el-popover",
                          {
                            ref: "popover",
                            staticClass: "searchPop",
                            attrs: {
                              placement: "top",
                              title: "批量搜索",
                              trigger: "click",
                              width: "378",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "close",
                                on: { click: _vm.closeSearchPanel },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                            _c("base-muti-input", {
                              ref: "baseMutiInput",
                              model: {
                                value: _vm.referenceNumber,
                                callback: function ($$v) {
                                  _vm.referenceNumber = $$v
                                },
                                expression: "referenceNumber",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  margin: "16px 0 0",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small" },
                                    on: { click: _vm.handleEmpty },
                                  },
                                  [_vm._v("清空")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "primary" },
                                    on: { click: _vm.handleConfirm },
                                  },
                                  [_vm._v("确定")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("el-input", {
                          staticClass: "input-search",
                          staticStyle: { width: "200px" },
                          attrs: {
                            value: _vm.searchVal,
                            placeholder: "请输入提单号搜索",
                          },
                          on: { focus: _vm.handleFocus },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "code" },
                      [
                        _c("span", [_vm._v("仓库代码")]),
                        _c("el-input", {
                          model: {
                            value: _vm.search,
                            callback: function ($$v) {
                              _vm.search = $$v
                            },
                            expression: "search",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "filter-btn",
                        on: { click: _vm.onSearch },
                      },
                      [_vm._v("搜索")]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "data" },
                  [
                    _c("BaseTable", {
                      attrs: {
                        height: 360,
                        tableData: _vm.tableData,
                        isPager: false,
                        border: true,
                      },
                      on: { handleSelectionChange: _vm.handleSelectionChange },
                    }),
                  ],
                  1
                ),
                _c("p", { staticClass: "selection" }, [
                  _vm._v("已选择 " + _vm._s(_vm.selectRow.length) + " 项"),
                ]),
              ],
        ],
        2
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _vm.step === 2
            ? _c(
                "div",
                {
                  staticClass: "ok flex_c_c",
                  staticStyle: { "margin-right": "20px" },
                  on: {
                    click: function ($event) {
                      return _vm.prevStep("form")
                    },
                  },
                },
                [_vm._v(" 上一步 ")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.step === 2 ? "确 定" : "下一步") + " ")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
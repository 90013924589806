var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Drawer",
    {
      attrs: { drawer: _vm.drawer, title: "详情", size: 820 },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        { staticClass: "view" },
        [
          _c("div", { staticClass: "panel" }, [
            _c("span", { staticClass: "panel-title" }, [
              _c("img", {
                attrs: { src: require("@/assets/images/bill/panel-icon.png") },
              }),
              _vm._v(" POD"),
            ]),
            _c(
              "div",
              {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    _vm.dialogVisible = true
                  },
                },
              },
              [
                _c("svg-icon", {
                  attrs: { iconClass: "upload", className: "icon" },
                }),
                _vm._v(" 上传POD "),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "pod-list" },
            [
              _c("BaseTable", {
                attrs: {
                  height: 480,
                  tableData: _vm.tableDataPol,
                  isPager: false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "podDate",
                    fn: function (row) {
                      return [
                        _c("p", { staticStyle: { "text-align": "left" } }, [
                          _c("span", { staticClass: "lable" }, [_vm._v("POD")]),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(row.scope.podFileId ? "是" : "否")),
                          ]),
                        ]),
                        _c("p", { staticStyle: { "text-align": "left" } }, [
                          _c("span", { staticClass: "lable" }, [
                            _vm._v("POD日期"),
                          ]),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(_vm._f("formatterTime")(row.scope.podDate))
                            ),
                          ]),
                        ]),
                        _c("p", { staticStyle: { "text-align": "left" } }, [
                          _c("span", { staticClass: "lable" }, [
                            _vm._v("POD时效"),
                          ]),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(row.scope.podPrescription)),
                          ]),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "deliveryDate",
                    fn: function (row) {
                      return [
                        _c("p", { staticStyle: { "text-align": "left" } }, [
                          _c("span", { staticClass: "lable" }, [
                            _vm._v("递送件数"),
                          ]),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(row.scope.deliveryQty)),
                          ]),
                        ]),
                        _c("p", { staticStyle: { "text-align": "left" } }, [
                          _c("span", { staticClass: "lable" }, [
                            _vm._v("递送板数"),
                          ]),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(row.scope.deliveryCbm)),
                          ]),
                        ]),
                        _c("p", { staticStyle: { "text-align": "left" } }, [
                          _c("span", { staticClass: "lable" }, [
                            _vm._v("递送日期"),
                          ]),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatterTime")(row.scope.deliveryDate)
                              )
                            ),
                          ]),
                        ]),
                        _c("p", { staticStyle: { "text-align": "left" } }, [
                          _c("span", { staticClass: "lable" }, [
                            _vm._v("递送时效"),
                          ]),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(row.scope.deliveryPrescription)),
                          ]),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "isISA",
                    fn: function (row) {
                      return [
                        _c(
                          "span",
                          {
                            style: { color: row.scope.isISA ? "#35BA83" : "" },
                          },
                          [_vm._v(_vm._s(row.scope.isISA ? "是" : "否"))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "opt",
                    fn: function (row) {
                      return [
                        _c(
                          "p",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.viewPod(row.scope, 0)
                                  },
                                },
                              },
                              [_vm._v("查看POD")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          [
                            !row.scope.isISA
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.uploadISA(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("上传ISA")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          [
                            row.scope.isISA
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewIsa(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("查看ISA")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "panel" }, [
            _c("span", { staticClass: "panel-title" }, [
              _c("img", {
                attrs: { src: require("@/assets/images/bill/panel-icon.png") },
              }),
              _vm._v(" BOL"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "pod-list" },
            [
              _c("BaseTable", {
                attrs: {
                  height: 240,
                  tableData: _vm.tableDataBol,
                  isPager: false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "opt",
                    fn: function (row) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.viewPod(row.scope, 1)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "panel", staticStyle: { "margin-top": "20px" } },
            [
              _c("span", { staticClass: "panel-title" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/bill/panel-icon.png"),
                  },
                }),
                _vm._v(" 费用 "),
              ]),
              _c(
                "div",
                {
                  staticClass: "add",
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible2 = true
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/images/add.png") },
                  }),
                  _vm._v(" 新增 "),
                ]
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "pod-list" },
            [
              _c("BaseTable", {
                attrs: {
                  height: 240,
                  tableData: _vm.tableDataFree,
                  isPager: false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "recievebleAmount",
                    fn: function (row) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(row.scope.recievebleAmount) +
                            " " +
                            _vm._s(_vm.currency) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "payableAmount",
                    fn: function (row) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(row.scope.payableAmount) +
                            " " +
                            _vm._s(_vm.currency) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("UploadBodDialog", {
            attrs: {
              dialogVisible: _vm.dialogVisible,
              bolOptions: _vm.tableDataBol.columns,
              mblSendTypeId: _vm.mblSendWayId,
            },
            on: {
              cancel: function ($event) {
                _vm.dialogVisible = false
              },
              ok: _vm.ok,
            },
          }),
          _c("UploadISADialog", {
            attrs: {
              dialogVisible: _vm.dialogVisible1,
              PODId: _vm.rowData?.id,
            },
            on: {
              cancel: function ($event) {
                _vm.dialogVisible1 = false
              },
              ok: _vm.ok,
            },
          }),
          _c("AddTruckFreeDialog", {
            attrs: {
              dialogVisible: _vm.dialogVisible2,
              mblSendTypeId: _vm.mblSendWayId,
            },
            on: {
              cancel: function ($event) {
                _vm.dialogVisible2 = false
              },
              ok: _vm.ok,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
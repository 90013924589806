<template>
  <el-dialog
    v-drag
    title="上传BOL"
    :visible.sync="dialogVisible"
    width="800px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div v-loading="loading" class="content" :style="{'--calcWidth': step === 1 ? 'calc(100% - 80px)' : '100%'}">
      <div class="step">
        <div class="step-item" :class="{ active: step > 0 }">
          <svg-icon iconClass="num-1" className="icon" />
          填写BOL信息
        </div>
        <svg-icon
          iconClass="arrow"
          className="icon"
          :class="{ active: step > 1 }"
        />
        <div class="step-item" :class="{ active: step > 1 }">
          <svg-icon iconClass="num-2" className="icon" />
          选择派送方式
        </div>
      </div>
      <template v-if="step === 1">
        <el-form
          ref="form"
          class="form"
          :model="form"
          :rules="rules"
          label-position="top"
        >
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item prop="type" label="上传类型">
                <el-radio-group v-model="form.type">
                  <el-radio :label="2">亚马逊仓</el-radio>
                  <el-radio :label="3">私仓</el-radio>
                  <el-radio :label="4">自提</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="PRONo" label="PRO#">
                <el-input v-model="form.PRONo" style="width: 320px" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item prop="supplierId" label="卡车公司">
                <el-select
                  v-model="form.supplierId"
                  style="width: 320px"
                  placeholder="请选择类别"
                >
                  <el-option
                    v-for="(item, $index) in supplyOptions"
                    :key="$index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="qty" label="件数">
                <el-input
                  v-model="form.qty"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  style="width: 320px;"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item prop="file" label="选择文件">
                <div class="upload">
                  <el-upload
                    class="upload-demo"
                    action="#"
                    :show-file-list="false"
                    :http-request="cutomerRequest"
                  >
                    <el-button class="btn" size="small">
                      <svg-icon iconClass="upload" className="icon" />
                      选择文件上传
                    </el-button>
                  </el-upload>
                </div>
                <div v-if="form.file" class="fileList">
                  <div class="file">
                    <div class="qun">
                      <el-progress
                        type="circle"
                        :width="40"
                        :height="40"
                        :stroke-width="4"
                        :percentage="100"
                      >
                      </el-progress>
                    </div>
                    <div class="file-item">
                      <p class="fileName">{{ form.file.name }}</p>
                      <p class="fileSize">{{ form.file.size | formatSize }} mb</p>
                    </div>
                  </div>
                  <div class="opt">
                    <i class="el-icon-delete" @click="deleteFile" />
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template v-else>
        <div class="filter">
          <div style="display: flex">
            <div class="search">
              <el-popover
                ref="popover"
                placement="top"
                class="searchPop"
                title="批量搜索"
                trigger="click"
                width="378"
              >
                <div class="close" @click="closeSearchPanel">
                  <i class="el-icon-close"></i>
                </div>
                <base-muti-input
                  ref="baseMutiInput"
                  v-model="referenceNumber"
                ></base-muti-input>
                <div style="text-align: right; margin: 16px 0 0">
                  <el-button size="small" @click="handleEmpty">清空</el-button>
                  <el-button
                    size="small"
                    type="primary"
                    @click="handleConfirm"
                  >确定</el-button
                  >
                </div>
              </el-popover>
              <el-input
                :value="searchVal"
                style="width: 200px"
                placeholder="请输入提单号搜索"
                class="input-search"
                @focus="handleFocus"
              ></el-input>
            </div>
            <div class="code">
              <span>仓库代码</span>
              <el-input v-model="search" />
            </div>
            <div class="filter-btn" @click="onSearch">搜索</div>
          </div>
        </div>
        <div class="data">
          <BaseTable :height="360" :tableData="tableData" :isPager="false" :border="true" @handleSelectionChange="handleSelectionChange">
          </BaseTable>
        </div>
        <p class="selection">已选择 {{ selectRow.length }} 项</p>
      </template>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div
        v-if="step === 2"
        class="ok flex_c_c"
        style="margin-right: 20px"
        @click="prevStep('form')"
      >
        上一步
      </div>
      <div v-loading="loading" class="ok flex_c_c" @click="ok('form')">
        {{ step === 2 ? "确 定" : "下一步" }}
      </div>
    </span>
  </el-dialog>
</template>

<script>
const BaseMutiInput = () => import('@/components/base/BaseMutiInput')
const BaseTable = () => import('@/components/table/BaseTable')
import { getTruckInfo, uploadBOL } from '@/api/bill'
import { getSupplierDropDownList } from '@/api/system'
export default {
  components: { BaseMutiInput, BaseTable },
  filters: {
    formatSize(size) {
      return (size / 1024 / 1024).toFixed(2)
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    mblSendWayIds: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      step: 1,
      form: {
        type: '',
        PRONo: '',
        supplierId: '',
        file: '',
        qty: ''
      },
      searchVal: '',
      rules: {
        type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        file: [{ required: true, message: '请选择文件', trigger: 'change' }],
        PRONo: [{ required: true, message: '请输入提单号', trigger: 'blur' }],
        supplierId: [{ required: true, message: '请选择卡车公司', trigger: 'change' }],
        qty: [{ required: true, message: '请输入件数', trigger: 'blur' }]
      },
      tableData: {
        fileds: [
          {
            filed: 'code',
            label: '仓库代码(邮编)',
            resizable: true
          },
          {
            filed: 'companyName',
            label: '客户',
            resizable: true
          },
          {
            filed: 'blNo',
            label: '提单号',
            width: '140px',
            resizable: true
          },
          {
            filed: 'containerNumber',
            label: '柜号',
            width: '120px',
            resizable: true
          },
          {
            filed: 'qty',
            label: '件数',
            width: '100px',
            resizable: true
          }
        ],
        mutiCheck: true,
        columns: [],
        total: 0
      },
      supplyOptions: [],
      backup: [],
      file: null,
      selectRow: [],
      referenceNumber: '',
      search: '',
      loading: false
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getSupplyList()
        this.step = 1
        this.$refs['form']?.resetFields()
      }
    }
  },
  methods: {
    cancel() {
      this.deleteFile()
      this.form = {
        type: '',
        PRONo: '',
        supplierId: '',
        file: '',
        qty: ''
      }
      this.referenceNumber = ''
      this.searchVal = ''
      this.search = ''
      this.$emit('cancel')
    },
    async ok(formName) {
      if (this.step === 2) {
        if (this.selectRow.length === 0) {
          this.$message.warning('请选择卡车行')
          return false
        }
        this.loading = true
        const formData = new FormData()
        formData.set('file', this.form.file)
        formData.set('input.type', this.form.type)
        formData.set('input.PRONo', this.form.PRONo)
        formData.set('input.supplierId', this.form.supplierId)
        formData.set('input.qty', this.form.qty)
         this.selectRow.forEach((a, index) => {
          formData.set(`input.mblSendWayIds[${index}]`, a.mblSendWayId)
        })
        const res = await uploadBOL(formData)
        this.loading = false
            if (res.data.success) {
              this.$message.success('上传成功')
              this.$emit('ok')
            } else {
              this.$message.error(res.data.errorMessage)
            }
        return
      }
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          this.step = 2
          try {
            const res = await getTruckInfo({
              type: this.form.type
            })
            if (res.success) {
              this.tableData.columns = res.data
              this.backup = res.data
            } else {
              this.$message.error(res.errorMessage)
            }
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    prevStep() {
      this.step = 1
      this.selectRow = []
    },
    async getSupplyList() {
      try {
        const res = await getSupplierDropDownList({
          scopValue: 'J' // 业务范畴 (eg: 提T 拆C 交J 账号 Z 清 Q 多个用 ','隔开)
        })
        if (res.success) {
          this.supplyOptions = res.data.map((item) => {
            return {
              value: item.id,
              label: `${item.code}-${item.name}`
            }
          })
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleFocus() {
      this.$refs.popover.doShow()
    },
    closeSearchPanel() {
      this.$refs.popover.doClose()
    },
    handleConfirm() {
      if (this.referenceNumber && this.referenceNumber !== '') {
        this.searchVal = this.referenceNumber.join(';')
        this.$refs.popover.doClose()
      }
    },
    handleEmpty() {
      this.referenceNumber = ''
      this.searchVal = ''
      this.$refs.baseMutiInput.reset()
      this.$refs.popover.doClose()
    },
    cutomerRequest({ file }) {
      this.form.file = file
    },
    handleSelectionChange(row) {
      this.selectRow = row
    },
    deleteFile() {
      this.form.file = null
    },
    onSearch() {
      const tableData = [...this.backup]
      if (this.search && this.referenceNumber) {
        this.tableData.columns = tableData.filter((item) => {
          if (item.code?.indexOf(this.search.toUpperCase()) > -1 && this.referenceNumber.indexOf(item.blNo) > -1) {
            return item
          }
        })
      } else if (this.search || this.referenceNumber) {
        this.tableData.columns = tableData.filter((item) => {
          if ((this.search && item.code?.indexOf(this.search.toUpperCase()) > -1) || (this.referenceNumber && this.referenceNumber.indexOf(item.blNo) > -1)) {
            return item
          }
        })
      } else {
        this.tableData.columns = this.backup
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: var(--calcWidth);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .step {
    width: 400px;
    height: 40px;
    background: #f7f7f7;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #eeeeee;
    margin: 0 auto;
    margin-top: -40px;
    display: flex;
    align-items: center;
    .icon {
      font-size: 14px;
    }
  }
  .active {
    color: #2395f3;
  }
  .step-item {
    width: 193px;
    height: 100%;
    text-align: center;
    line-height: 40px;
  }
  .form {
    width: 100%;
    height: auto;
    margin-top: 40px;
    &:deep(.el-form-item__label) {
      line-height: 30px;
    }
    .el-col:nth-child(2n) {
      display: flex;
      justify-content: flex-end;
    }
  }
  .label {
    width: auto;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
  }
  .require {
    color: #ff7444;
  }
  .upload {
    width: 320px;
    height: 100px;
    background: #f7f7f7;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    width: 160px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #2395f3;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    .icon {
      font-size: 24px;
      vertical-align: middle;
    }
  }
  .fileList {
    width: 300px;
    height: 40px;
    padding: 12px 10px;
    margin-top: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    display: flex;
    .file {
      display: flex;
      flex: 1;
    }
    .opt {
      line-height: 40px;
      font-size: 20px;
      color: #cdcdcd;
      cursor: pointer;
    }
  }
  .file-item {
    text-indent: 10px;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    line-height: 20px;
  }
  .fileName {
    color: #666666;
  }
  .fileSize {
    font-size: 12px;
    color: #999999;
  }
  .filter {
    display: flex;
    flex-flow: row-reverse;
    margin-top: 20px;
    .search {
      position: relative;
      display: flex;
      width: 200px;
      margin-right: 20px;
      .searchPop {
        /deep/ .el-popover {
          z-index: 9999;
          right: 0;
        }
        .close {
          position: absolute;
          top: 6px;
          right: 10px;
          cursor: pointer;
          font-size: 20px;
        }
      }
      .input-search {
        width: 166px;
        height: 40px !important;
        /deep/ .el-input__inner {
          height: 40px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
    .code {
      height: 40px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 40px;
      display: flex;
      margin-right: 10px;
      span {
        display: inline-block;
        width: 75px;
        margin-right: 10px;
      }
    }
    .filter-btn {
      width: 60px;
      height: 40px;
      background: #2395f3;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
    }
  }
  .data {
    width: 100%;
    height: auto;
    margin-top: 20px;
    /deep/ .fTable .el-table {
      border-radius: 4px 4px 4px 4px;
      overflow: hidden;
      .fp-cell {
        border: 0;
      }
      th.el-table__cell>.cell {
        padding-right: 10px;
      }
    }
  }
  .selection {
    width: 100%;
    height: 16px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
    margin-top: 10px;
  }
}

.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 20px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "上传POD",
        visible: _vm.dialogVisible,
        width: "800px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "content",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "PODDate", label: "POD时间" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "320px" },
                            attrs: {
                              type: "datetime",
                              placeholder: "选择日期时间",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.form.PODDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "PODDate", $$v)
                              },
                              expression: "form.PODDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "deliveryQty", label: "件数" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "320px" },
                            attrs: {
                              "controls-position": "right",
                              min: 1,
                              max: 10000,
                            },
                            model: {
                              value: _vm.form.deliveryQty,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "deliveryQty", $$v)
                              },
                              expression: "form.deliveryQty",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "deliveryDate", label: "递送时间" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "320px" },
                            attrs: {
                              type: "datetime",
                              placeholder: "选择日期时间",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.form.deliveryDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "deliveryDate", $$v)
                              },
                              expression: "form.deliveryDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "deliveryCbm", label: "板数" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "320px" },
                            attrs: {
                              "controls-position": "right",
                              min: 1,
                              max: 10000,
                            },
                            model: {
                              value: _vm.form.deliveryCbm,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "deliveryCbm", $$v)
                              },
                              expression: "form.deliveryCbm",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "BOLId", label: "选择BOL" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: { placeholder: "请选择BOL" },
                              model: {
                                value: _vm.form.BOLId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "BOLId", $$v)
                                },
                                expression: "form.BOLId",
                              },
                            },
                            _vm._l(_vm.options, function (item, $index) {
                              return _c("el-option", {
                                key: $index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("p", { staticClass: "label" }, [
                      _c("span", { staticClass: "require" }, [_vm._v("*")]),
                      _vm._v(" 选择POD文件"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "upload" },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "upload-demo",
                            attrs: {
                              action: "#",
                              limit: 1,
                              "file-list": _vm.fileList,
                              "show-file-list": false,
                              "http-request": _vm.cutomerRequest,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              { staticClass: "btn", attrs: { size: "small" } },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    iconClass: "upload",
                                    className: "icon",
                                  },
                                }),
                                _vm._v(" 选择文件上传 "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.file
                      ? _c("div", { staticClass: "fileList" }, [
                          _c("div", { staticClass: "file" }, [
                            _c(
                              "div",
                              { staticClass: "qun" },
                              [
                                _c("el-progress", {
                                  attrs: {
                                    type: "circle",
                                    width: 40,
                                    height: 40,
                                    "stroke-width": 4,
                                    percentage: 100,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "file-item" }, [
                              _c("p", { staticClass: "fileName" }, [
                                _vm._v(_vm._s(_vm.file.name)),
                              ]),
                              _c("p", { staticClass: "fileSize" }, [
                                _vm._v(
                                  _vm._s(_vm._f("formatSize")(_vm.file.size)) +
                                    " mb"
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "opt" }, [
                            _c("i", {
                              staticClass: "el-icon-delete",
                              on: { click: _vm.deleteFile },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
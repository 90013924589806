<template>
  <el-dialog
    v-drag
    title="上传BOL"
    :visible.sync="dialogVisible"
    width="800px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules" label-position="top">
        <el-form-item prop="reason" label="选择异常原因">
          <base-select-common
            ref="BaseSelectCommon"
            v-model="form.reason"
            width="600px"
            :type="18"
            title="拒绝原因"
          ></base-select-common>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
const BaseSelectCommon = () => import('@/components/base/BaseSelectCommon')
export default {
  components: { BaseSelectCommon },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    typeId: {
      type: Number,
      require: true
    }
  },
  data() {
    return {
      form: {
        reason: ''
      },
      rules: {
        reason: [{ required: true, message: '请输入选择异常原因', trigger: 'change' }]
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
            const reason = this.$refs.BaseSelectCommon.getValueLable(this.form.reason)
            // console.log('error', reason)
            this.$emit('ok', reason)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: left;
  .form {
    margin-left: 40px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 20px;
}

.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>

<template>
  <Drawer :drawer="drawer" title="详情" :size="820" @close="close">
    <div class="view">
      <div class="panel">
        <span class="panel-title">
          <img src="@/assets/images/bill/panel-icon.png" />
          POD</span
          >
        <div class="btn" @click="dialogVisible = true">
          <svg-icon iconClass="upload" className="icon" />
          上传POD
        </div>
      </div>
      <div class="pod-list">
        <BaseTable :height="480" :tableData="tableDataPol" :isPager="false">
          <template slot="podDate" slot-scope="row">
            <p style="text-align: left;">
              <span class="lable">POD</span>
              <span class="value">{{ row.scope.podFileId ? "是" : "否" }}</span>
            </p>
            <p style="text-align: left;">
              <span class="lable">POD日期</span>
              <span class="value">{{ row.scope.podDate | formatterTime }}</span>
            </p>
            <p style="text-align: left;">
              <span class="lable">POD时效</span>
              <span class="value">{{ row.scope.podPrescription }}</span>
            </p>
          </template>
          <template slot="deliveryDate" slot-scope="row">
            <p style="text-align: left;">
              <span class="lable">递送件数</span>
              <span class="value">{{ row.scope.deliveryQty }}</span>
            </p>
            <p style="text-align: left;">
              <span class="lable">递送板数</span>
              <span class="value">{{ row.scope.deliveryCbm }}</span>
            </p>
            <p style="text-align: left;">
              <span class="lable">递送日期</span>
              <span class="value">{{ row.scope.deliveryDate | formatterTime }}</span>
            </p>
            <p style="text-align: left;">
              <span class="lable">递送时效</span>
              <span class="value">{{ row.scope.deliveryPrescription }}</span>
            </p>
          </template>
          <template slot="isISA" slot-scope="row">
            <span :style="{ color: row.scope.isISA ? '#35BA83' : '' }">{{
              row.scope.isISA ? "是" : "否"
            }}</span>
          </template>
          <template slot="opt" slot-scope="row">
            <p><el-button
              type="text"
              @click="viewPod(row.scope, 0)"
            >查看POD</el-button
            ></p>
            <p>
              <el-button
                v-if="!row.scope.isISA"
                type="text"
                @click="uploadISA(row.scope)"
              >上传ISA</el-button
              >
            </p>
            <p>
              <el-button
                v-if="row.scope.isISA"
                type="text"
                @click="viewIsa(row.scope)"
              >查看ISA</el-button
              >
            </p>
          </template>
        </BaseTable>
      </div>
      <div class="panel">
        <span class="panel-title">
          <img src="@/assets/images/bill/panel-icon.png" />
          BOL</span
        >
      </div>
      <div class="pod-list">
        <BaseTable :height="240" :tableData="tableDataBol" :isPager="false">
          <template slot="opt" slot-scope="row">
            <el-button type="text" @click="viewPod(row.scope, 1)">查看</el-button>
          </template>
        </BaseTable>
      </div>
      <div class="panel" style="margin-top: 20px">
        <span class="panel-title">
          <img src="@/assets/images/bill/panel-icon.png" />
          费用
        </span>
        <div class="add" @click="dialogVisible2 = true">
          <img src="@/assets/images/add.png" />
          新增
        </div>
      </div>
      <div class="pod-list">
        <BaseTable :height="240" :tableData="tableDataFree" :isPager="false">
          <template slot="recievebleAmount" slot-scope="row">
            {{ row.scope.recievebleAmount }} {{ currency }}
          </template>
          <template slot="payableAmount" slot-scope="row">
            {{ row.scope.payableAmount }} {{ currency }}
          </template>
        </BaseTable>
      </div>
      <UploadBodDialog
        :dialogVisible="dialogVisible"
        :bolOptions="tableDataBol.columns"
        :mblSendTypeId="mblSendWayId"
        @cancel="dialogVisible = false"
        @ok="ok"
      ></UploadBodDialog>
      <UploadISADialog
        :dialogVisible="dialogVisible1"
        :PODId="rowData?.id"
        @cancel="dialogVisible1 = false"
        @ok="ok">
      </UploadISADialog>
      <AddTruckFreeDialog
        :dialogVisible="dialogVisible2"
        :mblSendTypeId="mblSendWayId"
        @cancel="dialogVisible2 = false"
        @ok="ok">
      </AddTruckFreeDialog>
    </div>
  </Drawer>
</template>

<script>
const Drawer = () => import('@/components/drawer/Drawer')
const BaseTable = () => import('@/components/table/BaseTable')
import UploadBodDialog from './UploadBodDialog'
import UploadISADialog from './UploadISADialog'
import AddTruckFreeDialog from './AddTruckFreeDialog'
import { truckDetail } from '@/api/bill'
import config from '@/utils/config'
export default {
  components: { Drawer, BaseTable, UploadBodDialog, UploadISADialog, AddTruckFreeDialog },
  filters: {
    formatterTime(date) {
      if (date) {
        return date.substr(0, 16)
      } else {
        return ''
      }
    }
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    mblSendWayId: {
      type: String,
      default: ''
    },
    currency: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tableDataPol: {
        fileds: [
          {
            filed: 'podDate',
            label: 'POD信息',
            width: '220px',
            isCustom: true
          },
          {
            filed: 'deliveryDate',
            label: '递送信息',
            width: '220px',
            isCustom: true
          },
          {
            filed: 'supplierCode',
            label: '卡车公司代码',
            width: '100px'
          },
          {
            filed: 'proNo',
            label: 'PRO#',
            width: '90px'
          },
          {
            filed: 'isISA',
            label: 'ISA',
            width: '50px',
            isCustom: true,
            formatter: (row) => {
              return row.isISA ? '是' : '否'
            }
          },
          {
            filed: 'opt',
            label: '操作',
            width: '',
            isCustom: true
          }
        ],
        columns: [],
        total: 0
      },
      tableDataBol: {
        fileds: [
          {
            filed: 'supplierCode',
            label: '卡车公司代码',
            width: ''
          },
          {
            filed: 'proNo',
            label: 'PRO#',
            width: ''
          },
          {
            filed: 'createdOn',
            label: '上传时间',
            width: '',
            formatter: row => {
              return row.createdOn.substr(0, 16)
            }
          },
          {
            filed: 'createdName',
            label: '上传人',
            width: ''
          },
          {
            filed: 'opt',
            label: '操作',
            width: '',
            isCustom: true
          }
        ],
        columns: [],
        total: 0
      },
      tableDataFree: {
        fileds: [
          {
            filed: 'code',
            label: '卡车公司代码',
            width: '145px'
          },
          {
            filed: 'recievebleAmount',
            label: '应收',
            width: '145px',
            isCustom: true
          },
          {
            filed: 'payableAmount',
            label: '应付',
            width: '145px',
            isCustom: true
          },
          {
            filed: 'remark',
            label: '备注',
            width: ''
          }
        ],
        columns: [],
        total: 0
      },
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      rowData: null
    }
  },
  computed: {
  },
  watch: {
    drawer(val) {
      if (val) {
        this.getList()
      }
    }
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async getList() {
      try {
        const res = await truckDetail({
          mblSendWayId: this.mblSendWayId
        })
        if (res.success) {
          this.tableDataPol.columns = res.data.pod
          this.tableDataBol.columns = res.data.bol
          this.tableDataFree.columns = res.data.surcharge
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    ok() {
      this.dialogVisible = false
      this.dialogVisible1 = false
      this.dialogVisible2 = false
      this.getList()
    },
    uploadISA(row) {
      this.rowData = row
      this.dialogVisible1 = true
    },
    viewPod(row, type) {
      !type && window.open(`/backEnd/api/${config.supplyCode}/File/preview?guid=${row.podGuid}`)
      type && window.open(`/backEnd/api/${config.supplyCode}/File/preview?guid=${row.bolGuid}`)
    },
    viewIsa(row) {
      window.open(`/backEnd/api/${config.supplyCode}/File/preview?guid=${row.isaGuid}`)
    }
  }
}
</script>

<style lang="less" scoped>
.view {
  width: 100%;
  height: auto;
  padding: 20px 0;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  box-sizing: border-box;
  .title {
    width: 72px;
    height: 21px;
    font-size: 16px;
    color: #333333;
    line-height: 21px;
    margin-left: 20px;
  }
  .panel {
    width: 780px;
    padding: 0 20px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    .panel-title {
      height: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 20px;
      position: relative;
      padding-top: 10px;
      img {
        padding-top: 2px;
      }
    }
    .icon {
      font-size: 20px;
      vertical-align: text-bottom;
    }
    .btn {
      width: 100px;
      height: 30px;
      background: #35ba83;
      border-radius: 2px 2px 2px 2px;
      line-height: 30px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      padding: 0;
      text-align: center;
      cursor: pointer;
    }
  }
  .pod-list {
    width: 780px;
    height: auto;
    padding: 10px 20px 0;
  }
  .add {
    width: 80px;
    height: 30px;
    background: #35ba83;
    border-radius: 2px 2px 2px 2px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    img {
      vertical-align: text-top;
    }
  }
  .lable {
    width: 80px;
    display: inline-block;
    height: 20px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    background: #f7f7f7;
    border-radius: 2px 2px 2px 2px;
    vertical-align: bottom;
    text-align: center;
  }
  .value {
    height: 20px;
    font-size: 14px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    line-height: 20px;
    margin-left: 5px;
  }
}

/deep/ .el-drawer__body {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
